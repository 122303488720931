import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import DashboardLayout from './components/layout/DashboardLayout';
import PublicLayout from './components/layout/PublicLayout';
import LoadingOverlay from './components/common/LoadingOverlay';
import VerificationRequiredPage from './pages/VerificationRequiredPage';
import LoadingScreen from './components/common/LoadingScreen';
import './services/axiosConfig';
import SessionsPage from './pages/SessionsPage';
import CookieConsent from './components/common/CookieConsent';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';

// Lazy load only non-critical components
const ImportDataPage = lazy(() => import('./pages/ImportDataPage'));
const AdvancedImportDataPage = lazy(() => import('./pages/AdvancedImportDataPage'));
const HomePage = lazy(() => import('./pages/HomePage'));
const DataManagementPage = lazy(() => import('./pages/DataManagementPage'));
const ProductManagementPage = lazy(() => import('./pages/ProductManagement'));
const NewsPage = lazy(() => import('./pages/news/NewsPage'));
const Login = lazy(() => import('./components/auth/Login'));
const Signup = lazy(() => import('./components/auth/Signup'));
const SubscriptionPlans = lazy(() => 
  import('./components/subscription/SubscriptionPlans').then(module => ({
    default: module.default,
    __esModule: true,
  }))
);
const ProfilePage = lazy(() => import('./pages/ProfilePage'));
const LandingPage = lazy(() => import('./pages/LandingPage'));
const AuthLayout = lazy(() => import('./components/layout/AuthLayout'));
const VerifyEmailPage = lazy(() => import('./pages/VerifyEmailPage'));
const PricingPage = lazy(() => 
  import('./pages/PricingPage').then(module => ({
    default: module.default,
    __esModule: true,
  }))
);
const PaymentSuccess = lazy(() => import('./pages/payment/PaymentSuccess'));
const PaymentCancel = lazy(() => import('./pages/payment/PaymentCancel'));
const StripeWrapper = lazy(() => import('./components/payment/StripeWrapper'));
const DataStatusPage = lazy(() => import('./pages/DataStatusPage'));
const PrivacyPolicyPage = lazy(() => import('./pages/PrivacyPolicyPage'));
const ActiveUsersPage = lazy(() => import('./pages/admin/ActiveUsersPage'));
const BusinessHealthPage = lazy(() => import('./pages/admin/BusinessHealthPage'));
const SessionInvalidPage = lazy(() => import('./pages/SessionInvalidPage'));
const NavigatedAnalysisPage = lazy(() => import('./pages/NavigatedAnalysisPage'));
const CompanyManagementPage = lazy(() => import('./pages/admin/CompanyManagementPage'));

const WhatsappPage = lazy(() => import('./pages/admin/WhatsAppAutomationPage'));
const AboutPage = lazy(() => import('./pages/AboutPage'));
const ContactPage = lazy(() => import('./pages/ContactPage'));
const WaitingListPage = lazy(() => import('./pages/WaitingListPage'));
const WaitingListManagementPage = lazy(() => import('./pages/admin/WaitingListManagementPage'));

const MarketAnalysisPage = lazy(() => import('./pages/analysis/MarketAnalysisPage'));
const VesselsPage = lazy(() => import('./pages/vessels/VesselsPage'));

const NewLandingPage = lazy(() => import('./pages/NewLandingPage'));
const ForgotPassword = lazy(() => import('./components/auth/ForgotPassword'));
const ResetPassword = lazy(() => import('./components/auth/ResetPassword'));
const TermsOfUsePage = lazy(() => import('./pages/TermsOfUsePage'));

// Protected Route component
const ProtectedRoute = ({ children, requireVerification = true }) => {
  const { user } = useAuth();
  const location = useLocation();
  
  if (!user) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  if (!user.isVerified && requireVerification) {
    return <Navigate to="/verification-required" />;
  }

  return children;
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 1
    }
  }
});

function AppRoutes() {
  return (
    <Router>
      <Suspense fallback={<LoadingScreen />}>
        <Routes>
          <Route path="/new-landing" element={
            <PublicLayout>
              <Suspense fallback={<LoadingOverlay />}>
                <NewLandingPage />
              </Suspense>
            </PublicLayout>
          } />
          <Route path="/login" element={
            <AuthLayout>
              <Suspense fallback={<LoadingOverlay />}>
                <Login />
              </Suspense>
            </AuthLayout>
          } />
          <Route path="/forgot-password" element={
            <AuthLayout>
              <Suspense fallback={<LoadingOverlay />}>
                <ForgotPassword />
              </Suspense>
            </AuthLayout>
          } />
          <Route path="/reset-password" element={
            <AuthLayout>
              <Suspense fallback={<LoadingOverlay />}>
                <ResetPassword />
              </Suspense>
            </AuthLayout>
          } />
          <Route path="/signup" element={
            <AuthLayout>
              <Suspense fallback={<LoadingOverlay />}>
                <Signup />
              </Suspense>
            </AuthLayout>
          } />
          <Route path="/verify-email" element={
            <AuthLayout>
              <Suspense fallback={<LoadingOverlay />}>
                <VerifyEmailPage />
              </Suspense>
            </AuthLayout>
          } />
          <Route path="/verification-required" element={
            <AuthLayout>
              <Suspense fallback={<LoadingOverlay />}>
                <VerificationRequiredPage />
              </Suspense>
            </AuthLayout>
          } />

          <Route path="/" element={
            <PublicLayout>
              <Suspense fallback={<LoadingOverlay />}>
                <LandingPage />
              </Suspense>
            </PublicLayout>
          } />
          
          <Route path="/waiting-list" element={
            <PublicLayout>
              <Suspense fallback={<LoadingOverlay />}>
                <WaitingListPage />
              </Suspense>
            </PublicLayout>
          } />
          
          <Route path="/dashboard/*" element={
            <ProtectedRoute>
              <DashboardLayout>
                <Suspense fallback={<LoadingOverlay />}>
                  <Routes>
                    <Route index element={<HomePage />} />
                    <Route path="data-status" element={<DataStatusPage />} />
                    <Route path="news" element={<NewsPage />} />
                    <Route path="data-upload" element={
                      <ProtectedRoute requireAdmin={true}>
                        <ImportDataPage />
                      </ProtectedRoute>
                    } />
                    <Route path="v2/analysis/:itemId" element={<MarketAnalysisPage />} />
                    <Route path="analysis/:itemId" element={<NavigatedAnalysisPage />} />
                    <Route path="manage-data" element={<DataManagementPage />} />
                    <Route path="advanced-import-data" element={
                      <ProtectedRoute>
                        <AdvancedImportDataPage />
                      </ProtectedRoute>
                    } />
                    <Route path="manage-data" element={
                      <ProtectedRoute>
                        <DataManagementPage />
                      </ProtectedRoute>
                    } />
                    <Route path="product-management" element={
                      <ProtectedRoute adminOnly={true}>
                        <ProductManagementPage />
                      </ProtectedRoute>
                    } />
                    <Route path="profile" element={<ProfilePage />} />
                    <Route path="subscription" element={
                      <ProtectedRoute requireSubscription={false}>
                        <Suspense fallback={<LoadingOverlay />}>
                          <StripeWrapper>
                            <SubscriptionPlans />
                          </StripeWrapper>
                        </Suspense>
                      </ProtectedRoute>
                    } />
                    <Route path="users/:userId/profile" element={<ProfilePage />} />
                      <Route path="sessions" element={
                        <ProtectedRoute>
                          <SessionsPage />
                        </ProtectedRoute>
                      } />
                    <Route path="active-users" element={
                      <ProtectedRoute adminOnly={true}>
                        <ActiveUsersPage />
                      </ProtectedRoute>
                    } />
                    <Route path="business-health" element={
                      <ProtectedRoute adminOnly={true}>
                        <BusinessHealthPage />
                      </ProtectedRoute>
                    } />
                    <Route path="company-management" element={
                      <ProtectedRoute adminOnly={true}>
                        <CompanyManagementPage />
                      </ProtectedRoute>
                    } />
                    <Route path="whatsapp-automation" element={
                      <ProtectedRoute adminOnly={true}>
                        <WhatsappPage />
                      </ProtectedRoute>
                    } />
                    <Route path="waiting-list-management" element={
                      <ProtectedRoute adminOnly={true}>
                        <WaitingListManagementPage />
                      </ProtectedRoute>
                    } />
                    <Route path="vessels" element={
                      <ProtectedRoute adminOnly={true}>
                        <VesselsPage />
                      </ProtectedRoute>
                    } />
                  </Routes>
                </Suspense>
              </DashboardLayout>
            </ProtectedRoute>
          } />
          <Route path="/pricing" element={
            <PublicLayout>
              <Suspense fallback={<LoadingOverlay />}>
                <PricingPage />
              </Suspense>
            </PublicLayout>
          } />
          <Route path="/about" element={
            <PublicLayout>
              <Suspense fallback={<LoadingOverlay />}>
                <AboutPage />
              </Suspense>
            </PublicLayout>
          } />
          <Route path="/contact" element={
            <PublicLayout>
              <Suspense fallback={<LoadingOverlay />}>
                <ContactPage />
              </Suspense>
            </PublicLayout>
          } />
          <Route path="/payment/success" element={
            <PublicLayout>
              <Suspense fallback={<LoadingOverlay />}>
                <StripeWrapper>
                  <PaymentSuccess />
                </StripeWrapper>
              </Suspense>
            </PublicLayout>
          } />
          <Route path="/payment/cancel" element={
            <PublicLayout>
              <Suspense fallback={<LoadingOverlay />}>
                <StripeWrapper>
                  <PaymentCancel />
                </StripeWrapper>
              </Suspense>
            </PublicLayout>
          } />
          <Route path="/privacy-policy" element={
            <PublicLayout>
              <Suspense fallback={<LoadingOverlay />}>
                <PrivacyPolicyPage />
              </Suspense>
            </PublicLayout>
          } />
          <Route path="/terms-of-use" element={
            <PublicLayout>
              <Suspense fallback={<LoadingOverlay />}>
                <TermsOfUsePage />
              </Suspense>
            </PublicLayout>
          } />
          <Route path="/session-invalid" element={
            <PublicLayout>
              <Suspense fallback={<LoadingOverlay />}>
                <SessionInvalidPage />
              </Suspense>
            </PublicLayout>
          } />
        </Routes>
      </Suspense>
    </Router>
  );
}

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <I18nextProvider i18n={i18n}>
        <AuthProvider>
          <AppRoutes />
          <CookieConsent />
        </AuthProvider>
      </I18nextProvider>
    </QueryClientProvider>
  );
}

export default App;

