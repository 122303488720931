import React, { useState, useEffect, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import { motion, AnimatePresence } from 'framer-motion';
import { HomeIcon, ChartBarIcon, CurrencyDollarIcon, CheckIcon, UserIcon, ArrowRightOnRectangleIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import { Menu, Transition } from '@headlessui/react';
import { LanguageIcon } from '@heroicons/react/24/outline';
import { languageConfig } from '../../i18n';

const PublicLayout = ({ children }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isLanguageMenuOpen, setIsLanguageMenuOpen] = useState(false);
  const { t, i18n } = useTranslation('layout');

  useEffect(() => {
    const handleClickOutside = () => {
      setIsLanguageMenuOpen(false);
    };

    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, []);

  const navigationLinks = [
    { 
      name: t('navigation.analytics'), 
      href: '/dashboard',
      icon: ChartBarIcon,
    },
    { 
      name: t('navigation.pricing'), 
      href: '/pricing',
      icon: CurrencyDollarIcon,
    },
    { 
      name: t('navigation.waitingList') || 'Join Waiting List', 
      href: '/waiting-list',
      icon: CheckIcon,
    },
  ];

  const actionLinks = [
    {
      name: t('navigation.getStarted'),
      href: '/signup',
      icon: ArrowRightOnRectangleIcon,
      primary: true
    }
  ];

  const enabledLanguages = Object.entries(languageConfig)
    .filter(([_, config]) => config.enabled)
    .map(([code, config]) => ({
      code,
      name: config.name
    }));

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem('i18nextLng', lng);
  };

  const LanguageSelector = () => (
    <Menu as="div" className="relative">
      <Menu.Button className="
        flex items-center px-3 py-2 text-sm font-medium rounded-md
        text-gray-600 dark:text-gray-300 
        hover:text-accent-500 hover:bg-gray-100
        dark:hover:text-accent-400 dark:hover:bg-gray-800
      ">
        <LanguageIcon className="h-5 w-5 mr-2" />
        {t('language.title')}
      </Menu.Button>

      <Transition
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="
          absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md
          bg-white dark:bg-gray-800 py-1 shadow-lg
          ring-1 ring-black ring-opacity-5 focus:outline-none
        ">
          {enabledLanguages.map((language) => (
            <Menu.Item key={language.code}>
              {({ active }) => (
                <button
                  onClick={() => changeLanguage(language.code)}
                  className={`
                    ${active ? 'bg-gray-100 dark:bg-gray-700' : ''}
                    ${i18n.language === language.code ? 'text-accent-600 dark:text-accent-400' : 'text-gray-700 dark:text-gray-200'}
                    group flex w-full items-center px-4 py-2 text-sm
                  `}
                >
                  {language.name}
                </button>
              )}
            </Menu.Item>
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  );

  return (
    <div className="bg-white dark:bg-gray-900">
      {/* Navigation */}
      <nav className="fixed top-0 left-0 right-0 bg-white/80 dark:bg-gray-900/80 backdrop-blur-md z-50 border-b border-gray-200 dark:border-gray-800">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between items-center h-16">
            {/* Logo and Brand Name */}
            <div className="flex items-center flex-shrink-0">
              <Link to="/" className="flex items-center">
                <div className="relative w-8 h-8 flex-shrink-0">
                  <img 
                    src="/logo.webp" 
                    alt="Logo" 
                    className="object-contain"
                    width={500} 
                    height={500}
                  />
                </div>
                <span className="ml-2 text-xl font-semibold text-gray-900 dark:text-white whitespace-nowrap">
                  {t('navigation.brand')}
                </span>
              </Link>
            </div>

            {/* Desktop Navigation */}
            <div className="hidden md:flex items-center">
              {/* Main Navigation Links */}
              <div className="flex items-center space-x-1">
                {navigationLinks.map((link) => (
                  <Link
                    key={link.name}
                    to={link.href}
                    className="flex items-center px-3 py-2 text-sm font-medium rounded-md
                      text-gray-600 dark:text-gray-300 
                      hover:text-accent-500 hover:bg-gray-100
                      dark:hover:text-accent-400 dark:hover:bg-gray-800"
                  >
                    <link.icon className="h-5 w-5 mr-2" />
                    {link.name}
                  </Link>
                ))}
                <LanguageSelector />
              </div>

              {/* Separator Line */}
              <div className="w-px h-6 bg-gray-200 dark:bg-gray-700 mx-4" />

              {/* Action Links */}
              <div className="flex items-center space-x-1">
                {actionLinks.map((link) => (
                  <Link
                    key={link.name}
                    to={link.href}
                    className={`flex items-center px-3 py-2 text-sm font-medium rounded-md
                      ${link.primary 
                        ? 'bg-accent-500 text-white hover:bg-accent-600' 
                        : 'text-gray-600 dark:text-gray-300 hover:text-accent-500 hover:bg-gray-100 dark:hover:text-accent-400 dark:hover:bg-gray-800'
                      }`}
                  >
                    <link.icon className="h-5 w-5 mr-2" />
                    {link.name}
                  </Link>
                ))}
              </div>
            </div>

            {/* Mobile menu button */}
            <div className="md:hidden">
              <button
                onClick={() => setIsMenuOpen(!isMenuOpen)}
                className="inline-flex items-center justify-center p-2 rounded-md
                  bg-accent-50 dark:bg-gray-700/50 
                  text-accent-500 dark:text-accent-400"
                aria-label={isMenuOpen ? t('accessibility.closeMenu') : t('accessibility.openMenu')}
              >
                <span className="sr-only">
                  {isMenuOpen ? t('accessibility.closeMenu') : t('accessibility.openMenu')}
                </span>
                {isMenuOpen ? (
                  <XMarkIcon className="h-6 w-6" />
                ) : (
                  <Bars3Icon className="h-6 w-6" />
                )}
              </button>
            </div>
          </div>
        </div>

        {/* Mobile menu */}
        <AnimatePresence>
          {isMenuOpen && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ duration: 0.2 }}
              className="md:hidden border-t border-gray-200 dark:border-gray-800"
            >
              <div className="px-2 pt-2 pb-3 space-y-1 bg-white/80 dark:bg-gray-900/80 backdrop-blur-md">
                {/* Navigation Links */}
                {navigationLinks.map((link) => (
                  <Link
                    key={link.name}
                    to={link.href}
                    className="flex items-center px-3 py-2 rounded-md text-base font-medium
                      text-gray-600 dark:text-gray-300 
                      hover:text-accent-500 hover:bg-gray-50 
                      dark:hover:text-accent-400 dark:hover:bg-gray-800"
                    onClick={() => setIsMenuOpen(false)}
                  >
                    <link.icon className="h-5 w-5 mr-3" />
                    {link.name}
                  </Link>
                ))}

                {/* Language Selector in mobile menu */}
                <div className="relative">
                  <button
                    className="flex items-center w-full px-3 py-2 rounded-md text-base font-medium
                      text-gray-600 dark:text-gray-300 
                      hover:text-accent-500 hover:bg-gray-50 
                      dark:hover:text-accent-400 dark:hover:bg-gray-800"
                    onClick={(e) => {
                      e.stopPropagation();
                      setIsLanguageMenuOpen(!isLanguageMenuOpen);
                    }}
                  >
                    <LanguageIcon className="h-5 w-5 mr-3" />
                    {t('language.title')}
                    <span className="ml-auto text-sm text-gray-500 dark:text-gray-400">
                      {t(`language.${i18n.language}`)}
                    </span>
                  </button>

                  <AnimatePresence>
                    {isLanguageMenuOpen && (
                      <motion.div
                        initial={{ opacity: 0, y: -10 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -10 }}
                        transition={{ duration: 0.2 }}
                        className="
                          absolute left-0 right-0 mt-1 
                          bg-white dark:bg-gray-800 
                          rounded-md shadow-lg 
                          ring-1 ring-black ring-opacity-5
                          overflow-hidden
                          z-50
                        "
                      >
                        {enabledLanguages.map((language) => (
                          <button
                            key={language.code}
                            onClick={() => {
                              changeLanguage(language.code);
                              setIsLanguageMenuOpen(false);
                              setIsMenuOpen(false);
                            }}
                            className={`
                              flex w-full items-center px-4 py-2 text-base font-medium
                              ${i18n.language === language.code 
                                ? 'text-accent-600 dark:text-accent-400 bg-accent-50 dark:bg-accent-900/20' 
                                : 'text-gray-600 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-700'
                              }
                            `}
                          >
                            {language.name}
                            {i18n.language === language.code && (
                              <span className="ml-auto text-accent-500">
                                <CheckIcon className="h-5 w-5" />
                              </span>
                            )}
                          </button>
                        ))}
                      </motion.div>
                    )}
                  </AnimatePresence>
                </div>

                {/* Mobile Separator */}
                <div className="border-t border-gray-200 dark:border-gray-700 my-2" />

                {/* Action Links */}
                {actionLinks.map((link) => (
                  <Link
                    key={link.name}
                    to={link.href}
                    className={`flex items-center px-3 py-2 rounded-md text-base font-medium
                      ${link.primary 
                        ? 'bg-accent-500 text-white hover:bg-accent-600' 
                        : 'text-gray-600 dark:text-gray-300 hover:text-accent-500 hover:bg-gray-50 dark:hover:text-accent-400 dark:hover:bg-gray-800'
                      }`}
                    onClick={() => setIsMenuOpen(false)}
                  >
                    <link.icon className="h-5 w-5 mr-3" />
                    {link.name}
                  </Link>
                ))}
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </nav>

      {/* Main Content */}
      <main className="min-h-screen pt-16">
        {children}
      </main>

      {/* Footer */}
      <footer className="bg-white dark:bg-gray-900 border-t border-gray-200 dark:border-gray-800">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 py-12">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {/* Company Info */}
            <div className="col-span-1">
              <div className="flex items-center flex-shrink-0">
                <div className="relative w-8 h-8 flex-shrink-0">
                  <img 
                    src="/logo.webp" 
                    alt="Logo" 
                    className="object-contain"
                    width={500} 
                    height={500}
                  />
                </div>
                <span className="ml-2 text-xl font-semibold text-gray-900 dark:text-white whitespace-nowrap">
                  {t('navigation.brand')}
                </span>
              </div>
              <p className="mt-2 text-sm text-gray-500 dark:text-gray-400">
                Registration No.: 202501001648 (SSM)
              </p>
            </div>
            
            {/* Links Section */}
            <div className="col-span-1 grid grid-cols-2 gap-4">
              {/* Company Links */}
              <div className="flex flex-col">
                <h3 className="text-sm font-semibold text-gray-900 dark:text-white mb-3">
                  {t('footer.company', 'Company')}
                </h3>
                <div className="flex flex-col space-y-2">
                  <Link to="/about" className="text-sm text-gray-500 hover:text-accent-500 dark:text-gray-400 dark:hover:text-accent-400 transition-colors">
                    {t('navigation.about', 'About')}
                  </Link>
                  <Link to="/contact" className="text-sm text-gray-500 hover:text-accent-500 dark:text-gray-400 dark:hover:text-accent-400 transition-colors">
                    {t('navigation.contact', 'Contact')}
                  </Link>
                </div>
              </div>
              
              {/* Legal Links */}
              <div className="flex flex-col">
                <h3 className="text-sm font-semibold text-gray-900 dark:text-white mb-3">
                  {t('footer.legal', 'Legal')}
                </h3>
                <div className="flex flex-col space-y-2">
                  <Link to="/privacy-policy" className="text-sm text-gray-500 hover:text-accent-500 dark:text-gray-400 dark:hover:text-accent-400 transition-colors">
                    {t('footer.privacyPolicy', 'Privacy Policy')}
                  </Link>
                  <Link to="/terms-of-use" className="text-sm text-gray-500 hover:text-accent-500 dark:text-gray-400 dark:hover:text-accent-400 transition-colors">
                    {t('footer.termsOfUse', 'Terms of Use')}
                  </Link>
                </div>
              </div>
            </div>
            
            {/* Copyright - Aligned to the right on desktop, centered on mobile */}
            <div className="col-span-1 flex items-center justify-start md:justify-end">
              <p className="text-sm text-gray-500 dark:text-gray-400">
                {t('footer.copyright')}
              </p>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default PublicLayout; 